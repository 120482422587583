<template>
	<div class="cbox">
		<div v-if="false" class="headbar">

			<div style="padding-top: 14px">
				<div style=" width: 200px;  margin-top:-10px;">
					<div style="width:40px;height:40px;background-color: #ebf5ff; border-radius: 50%;float: left;">
						<img :src="'/img/page.png'" style="width:60%;height:60%;margin-left: 20%;margin-top:20%" />
					</div>
					<div style="margin-left: 50px; color:#535353; font-size:20px !important">就业统计表</div>
				</div>

			</div>
		</div>
		<div class="w_100 h_100 bcontent flexCloumn" id="myFullTable" @dblclick="initFullTables"
			style="padding:20px 0;background-color: #fff;">

			<div class="w_100 flexBetween">

				<el-tabs class="w_100" v-model="activeName" @tab-click="handleClick">

          <el-tab-pane label="就业数据统计表(合计)" name="合计" v-if="xuezhiList.length>1"></el-tab-pane>
					<el-tab-pane :label="'就业数据统计表('+item.xuezhi+')'" :name="item.xuezhi" v-for="(item,i) in xuezhiList"
						:key="i">

					</el-tab-pane>



				</el-tabs>
				<el-tabs class="" v-model="activeName">
					<el-tab-pane disabled>
						<div slot="label" class="flexStart">
							<el-button v-if="$store.state.pmid == 1" icon="el-icon-download" size="small"
									type="text" title="全部学制" @click="downloadAll">下载全部</el-button>

								<el-button class="ml20" @click="initFullTables" size="middle" type="text" title="全屏展示表格，也可双击表格全屏展示">
								<i class="el-icon-full-screen" style="font-size:24px!important;"></i></el-button>
						</div>
					</el-tab-pane>
				</el-tabs>
			</div>
			<div class="w_100" style="text-align: center;font-size: 30px !important; padding-bottom: 20px;">
				南京市职业学校{{year}}届毕业生就业数据统计表（{{activeName}}）

				<el-button class="ml10" @click="downXls" type="primary" plain circle size="mini"
					icon="el-icon-download"></el-button>
			</div>
			<el-table :data="tableData" row-key="id" stripe class="flex_1 mytable" header-row-class-name="mytableheader"
				height="100%" style="width:fit-content;width:100%;">
				<el-table-column type="index" label="序号" width="55px" align="center"></el-table-column>
				<!-- <el-table-column label="学校" prop="platform" min-width="150" align="center"></el-table-column> -->
				<el-table-column label="学校名称" prop="platform" min-width="120" align="center"></el-table-column>


				<el-table-column label="招生学生总数" prop="zs_num" width="120" align="center"></el-table-column>
				<el-table-column label="毕业学生总数" prop="bys_num" width="120" align="center"></el-table-column>
				<el-table-column label="就业学生总数" prop="jiuye_num" width="120" align="center"></el-table-column>

				<el-table-column label="直接就业学生总数" prop="zhijie_num" width="150" align="center"></el-table-column>
				<el-table-column label="升学学生总数" prop="shengxue_num" width="120" align="center"></el-table-column>

				<el-table-column label="参军学生人数" prop="canjun_num" width="120" align="center"></el-table-column>
				<el-table-column label="对口就业人数" prop="duikou_num" width="120" align="center"></el-table-column>
				<el-table-column label="其中升学本科人数" prop="shengxue_num" width="150" align="center"></el-table-column>
				<el-table-column label="待业学生数" prop="daiye_num" width="120" align="center"></el-table-column>


			</el-table>



		</div>


	</div>
</template>

<script>
	export default {
		components: {



		},
		data() {
			return {
				year:sessionStorage.getItem("year"),
				activeName: "",
				xuezhiList: [],
				tableData: []

			}
		},
		mounted() {
			this.getXuezhi()
		},
		methods: {
			getXuezhi() {
				this.$http.post("/api/get_xuezhi_list").then(res => {
					this.xuezhiList = res.data
          if (this.xuezhiList.length > 1) {
            this.activeName = '合计'
          } else {
            this.activeName = res.data[0].xuezhi
          }
					this.getData()
				})
			},

			getData() {
				let data = {
					xuezhi:this.activeName
				}
				this.$http.post("/api/get_jiuye_tongjitable", data).then(res => {
					this.tableData = res.data


				})
			},
			handleClick(){
				this.getData()
			},

				downXls() {
			this.$http.post("/api/export_jytjb", {
				xuezhi: this.activeName,
				year: this.year,
				single:1
			}).then(res => {
				if (res.data.url) {
					window.open(res.data.url, "_blank")
				}

			})
		},
		downloadAll() {
			this.$http.post("/api/export_jytjb_all", {
				xuezhiList: this.xuezhiListExport,
				year: this.year,
				single: 1
			}).then(res => {
				if (res.data.url) {
					window.open(res.data.url, "_blank")
				}

			})
		},

		}
	}
</script>